
  import { defineComponent, ref, onMounted, reactive, computed } from "vue";
  import { useStore } from "vuex";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  import SearchAndFilter from "@/views/still/search_and_filters.vue";
  import { ElMessageBox } from 'element-plus'

  import { ElNotification } from 'element-plus'


  import mAxiosApi from "@/api";
  import draggable from 'vuedraggable'

  export default defineComponent({
    name: "Overview",
    components: {Datatable, SearchAndFilter, draggable},
    props: {
      idNetwork: String,
      editMode: Boolean,
    },
    setup(props) {

    const store = useStore();
    const state = reactive({
        rules : [] as any,
        loaderEnabled: true,
        myList: [] as any,
        initialMyList: [] as any,
        loadingDatatable: 0,       
        editMode : false,
        displayBtns: false,
        errorsTests : "" as any,
        drag : false,
        currentSource : "",
        currentIDLng : "",
        categoriesOptions : [] as any,
        categoriesValue : [],

        gVlans: [] as any,
        gVlansInit: [] as any,
        loadingDatatableVLAN : 0,
        drawerVLAN : false,

        gObjects:[] as any,
        gObjectsInit: [] as any,
        loadingDatatableObj : 0,
        drawerObj : false,

        gObjectGroup: [] as any,
        gObjectsGroupInit: [] as any,
        loadingDatatableObjGroup : 0,
        drawerObjGroup : false,

        displayLinks : false,
        mNetworksLink : [] as any,
        applyLinks : false,
        linksStep : 0,
        currentLink : {} as any,

        groupeFirewall : [] as any,
        specRules: false,
        displayGrp: { id:null, name:''},

        drawerAddEditGrp: false,
        currentGrp: { seq:0, name:''},

        grpLinkNetwork: false,
        mGroupeLinkNetwork: [] as any,
        mInitialGroupeLinkNetwork: [] as any,
        loadingDatatableGroupLink: 0,
        filterLinkedGrp: true,

    });

    let tableHeader = ref([]) as any;
    if (props.editMode) {
      tableHeader = ref([
        { name: "ID", key: "id", sortable: false},
        { name: "Type", key: "policy", sortable: false},
        { name: "Désignation", key: "comment", sortable: false,},
        { name: "Protocol", key: "protocol", sortable: false,},
        { name: "Règles - Sources / Destination", key: "srcCidr", sortable: false,},
        { name: "", key: "action", sortable: false,},
      ]);
    }else{
      tableHeader = ref([
        { name: "ID", key: "id", sortable: false},
        { name: "Type", key: "policy", sortable: false},
        { name: "Désignation", key: "comment", sortable: false,},
        { name: "Protocol", key: "protocol", sortable: false,},
        { name: "Source Port", key: "srcPort", sortable: false,},
        { name: "Source CIDR", key: "srcCidr", sortable: false,},
        { name: "Destination Port", key: "destPort", sortable: false,},
        { name: "Destination CIDR", key: "destCidr", sortable: false,},
        { name: "", key: "action", sortable: false,},
      ]);
    }

    const openAddEditGrp = (id) => {
      state.currentGrp.seq = id;
      if(id) {
        console.log(state.groupeFirewall.filter((item: any) =>item.grp_seq == id));
        state.currentGrp.name = state.groupeFirewall.filter((item: any) =>item.grp_seq == id)[0].grp_label
      }else {
        state.currentGrp.name = '';
      }
      state.drawerAddEditGrp = true;
    };

    const addEditGrp = async() => {

      if(!state.currentGrp.name) {
        ElNotification({ title: 'Erreur', message: 'Veuillez saisir le libellé svp', type: 'error',})
        return false;
      }

      const form = JSON.stringify(state.currentGrp);
      await mAxiosApi.post("/addEdit_Grp_firewall?network_id=" + props.idNetwork , form, { headers: {   'Content-Type': 'application/json'  }  });
      state.drawerAddEditGrp = false;
      state.myList=[];
      buildList();
    };

    // GROUP LINK NETWORK
      const tableHeaderGroupLink = ref([
          { name: "Nom", key: "net_name", sortable: false},
          { name: "Id", key: "net_id", sortable: false,},
          { name: "", key: "xlien", sortable: false,},  
      ]);

      const displayGrpLinkNetwork = async() => {
        const mGroupeLinkNetwork = await getAxios("/groupeLinkNetwork?source_id=" + props.idNetwork+"&groupe="+state.displayGrp.id);

        mGroupeLinkNetwork.forEach(function(item) {
          item.xlien = item.xlien > 0;
        });

        state.mGroupeLinkNetwork = mGroupeLinkNetwork
        state.mInitialGroupeLinkNetwork =  state.mGroupeLinkNetwork;
        state.loadingDatatableGroupLink++;
        state.grpLinkNetwork = true;
      };

      const selectLineGroupLink = (data) => {
        data.xlien = !data.xlien;
        state.mGroupeLinkNetwork.filter((item: any) =>item.net_id == data.net_id)[0].xlien = !state.mGroupeLinkNetwork.filter((item: any) =>item.net_id == data.net_id)[0].xlien;
      };

      const editGroupeLinkedNetwork= async() => {
        const form = JSON.stringify({listNetworks : state.mGroupeLinkNetwork});
        await mAxiosApi.post("/editGrpLinkNetwork?groupe=" + state.displayGrp.id, form, { headers: {   'Content-Type': 'application/json'  }  });
        state.displayBtns = true;
        state.grpLinkNetwork = false;
      }
      const testFilterGRP = (el) => {
        return !(el.knum_groupe==state.displayGrp.id || state.displayGrp.id=='all');
      };
      const returnOpacityFilterGRP = (el) => {
        if (el.knum_groupe==state.displayGrp.id || state.displayGrp.id=='all') {
          return 1;
        }else {
          return 0.4;
        }
      };
      

    const tableFilters = ref([
      { name: "Type", key: "policy", label: "policy", },
    ]);

    const fieldCIDR = (data) => {
      const elements = data.split(',');
      const transformedElements = elements.map(element => {
        const trimmedElement = element.trim();
        if (trimmedElement.startsWith('GRP(')) {
          const matchEl = state.gObjectsGroupInit.filter((item: any) => item.id == trimmedElement.match(/GRP\((\d+)\)/)?.[1])[0];
          return "<div class='el-tag el-tag--info el-tag--plain mx-1 mt-1'>" + matchEl.name + "</div>";
        } else if (trimmedElement.startsWith('OBJ(')) {
          const matchEl = state.gObjectsInit.filter((item: any) =>item.id == trimmedElement.match(/OBJ\((\d+)\)/)?.[1])[0];
          return "<div class='el-tag el-tag--warning el-tag--plain mt-1'>" + matchEl.name + "</div>";
        } else if (trimmedElement.startsWith('VLAN(')) {
          const matchEl = state.gVlansInit.filter((item: any) =>item.id == trimmedElement.match(/VLAN\((\d+)\)/)?.[1])[0];
          return "<div class='el-tag el-tag--large el-tag--light mx-1 mt-1'>" + matchEl.name + "</div>";
        }
        return "<div class='el-tag el-tag--primary el-tag--plain mx-1 mt-1'>" + element + "</div>";
      });
      const formattedString = transformedElements.join('<br>');
      return formattedString;
    };


    const fieldCIDREdit = (data) => {
      if (!data) return;
      const elements = data.split(',');
      const transformedElements = elements.map(element => {
        const trimmedElement = element.trim();
        // Extrait le numéro entre les parenthèses si présent
        const rawValue = trimmedElement.match(/\((\d+)\)/)?.[1] || '';
        
        if (trimmedElement.startsWith('GRP(')) {
          const matchEl = state.gObjectsGroupInit.filter((item) => item.id == rawValue)[0];
          return { type: 'GRP', value: matchEl ? matchEl.name : 'Unknown', rawValue: trimmedElement };
        } else if (trimmedElement.startsWith('OBJ(')) {
          const matchEl = state.gObjectsInit.filter((item) => item.id == rawValue)[0];
          return { type: 'OBJ', value: matchEl ? matchEl.name : 'Unknown', rawValue: trimmedElement };
        } else if (trimmedElement.startsWith('VLAN(')) {
          const matchEl = state.gVlansInit.filter((item) => item.id == rawValue)[0];
          return { type: 'VLAN', value: matchEl ? matchEl.name : 'Unknown', rawValue: trimmedElement };
        }
        return { type: 'FREE', value: trimmedElement, rawValue: trimmedElement };
      });
      return transformedElements;
    }

    onMounted(async () => {  
      if (!store.getters.getOrg.idOrg) return false;
      state.groupeFirewall
      buildList();
    });

    const buildList = async () => {
        
        state.displayBtns=false;
        state.loaderEnabled = true;
        state.editMode = props.editMode;

        state.errorsTests = "";
        
        const [mRules,mGroupeFirewall,mObjects,mObjectGroup,mVlans,mNetworksLink] = await Promise.all([
        getAxios("/firewall?edit_mode="+state.editMode+"&network_id=" + props.idNetwork), 
        getAxios("/groupeFirewall?network_id=" + props.idNetwork),
        getAxios("/policiesObjects?organization_id=" + store.getters.getOrg.idOrg),
        getAxios("/policiesObjectsGroup?organization_id=" + store.getters.getOrg.idOrg + "&network_id=" + props.idNetwork),
        getAxios("/vlans?network_id=" + props.idNetwork),
        getAxios("/networksLink?source_id=" + props.idNetwork)
        ])
     
        state.myList = mRules;
        addCategoryToSelectFilter(mRules)
        let mID = 0;
        state.myList.forEach(function(item) {
          item.id = mID;
          item.action = mID;
          mID += 1;
        });
        state.initialMyList = state.myList;

        state.groupeFirewall = mGroupeFirewall;
        state.mNetworksLink = mNetworksLink;

        mObjects.forEach(element => {
          element.select = false;
        });
        state.gObjects = mObjects;
        state.gObjectsInit = mObjects;

        mObjectGroup.forEach(element => {
          element.select = false;
        });
        state.gObjectGroup = mObjectGroup;
        state.gObjectsGroupInit = mObjectGroup;

        mVlans.reponse.forEach(element => {
          element.select = false;
        });
        state.gVlans = mVlans.reponse;
        state.gVlansInit = mVlans.reponse;
        
        state.loaderEnabled = false;
        state.loadingDatatable += 1;
        
      }

      const recalcLngs = () => {
        let mID = 0;
        state.myList.forEach(function(item) {
          item.id = mID;
          item.action = mID;
          mID += 1;
        });
      };

      const addEmptyRowAtStart = () => {

        state.displayBtns = true;
        
        const myList = state.myList;
        if (myList.length === 0) {
            return;
        }
        const keys = Object.keys(myList[0]);
        const emptyObject = keys.reduce((acc, key) => {
            acc[key] = "";
            return acc;
        }, {});
        myList.unshift(emptyObject);

        myList[0].policy = 'allow';
        myList[0].protocol = 'any';
        myList[0].srcCidr = "";
        myList[0].destCidr = "";
        myList[0].syslogEnabled = false;

        if (state.displayGrp.id){
           myList[0].knum_groupe = state.displayGrp.id;
        }else {
          myList[0].knum_groupe = null;
        }

        state.myList = myList;

        recalcLngs();

        state.loadingDatatable += 1;
      };

      const delLignes = (id) => {
        state.displayBtns = true;
        state.myList.splice(state.myList.findIndex(el => el.id == id), 1)
        
        recalcLngs();
                
      };

      
      const dropRule = async () => {
        recalcLngs();
        state.displayBtns=true;
      };


      const delElement = (source, idLng, rawValue, el) => {

        if (testFilterGRP(el)) return false;

        state.displayBtns = true;
        let mStr = "";
        if (source == 'SRC') mStr = state.myList[idLng].srcCidr;
        if (source == 'DEST') mStr = state.myList[idLng].destCidr;

        const elements = mStr.split(',');
        let strOut = "";
        elements.forEach(function(item) {
          if(item != rawValue) strOut += "," + item
        });
        
        if (source == 'SRC') state.myList[idLng].srcCidr = strOut.slice(1);
        if (source == 'DEST') state.myList[idLng].destCidr = strOut.slice(1);

        state.loadingDatatable += 1;


      };

    // ADD VLAN 
    const tableHeaderVLAN = ref([
        { name: "ID", key: "id", sortable: false},
        { name: "Nom", key: "name", sortable: false},
        { name: "IP", key: "applianceIp", sortable: false,},
        { name: "Subnet", key: "subnet", sortable: false,},
      ]);

      const selectLineVLAN = (data) => {
        data.select = !data.select;
        state.gVlans.filter((item: any) =>item.id == data.id)[0].select = !state.gVlans.filter((item: any) =>item.id == data.id)[0].select;
      };

      const promptVLAN = (source,idLng) => {
        state.gVlans.forEach(element => {
          element.select = false;
        });

        state.currentSource = source;
        state.currentIDLng = idLng;
        state.drawerVLAN = true;
      }

      const ajoutVLAN = () => {
        state.gVlans.forEach(function(item) {
          if (item.select) {
            const value = "VLAN("+ item.id +").*";
            if (state.currentSource == 'source') state.myList[state.currentIDLng].srcCidr += (state.myList[state.currentIDLng].srcCidr ? "," : "") + value;
            if (state.currentSource == 'destination') state.myList[state.currentIDLng].destCidr += (state.myList[state.currentIDLng].destCidr ? "," : "") + value;
          }
        });
        state.loadingDatatable += 1;
        state.displayBtns = true;
        state.drawerVLAN = false;
      }
      
      const addLine = (element, action : "duplicate" | "add_after" | "add_before") => {
        const keys = Object.keys(state.myList[0]);
        const emptyObject = keys.reduce((acc, key) => {
          acc[key] = "";
          return acc;
        }, {});
        const id = element.id
        const list_id = state.myList.findIndex(el => el.id == id)
        state.displayBtns = true;
        if (action === "add_after" ){
          const new_id = list_id + 1
          state.myList.splice(new_id, 0,emptyObject)
          state.myList[new_id].policy = 'allow';
          state.myList[new_id].protocol = 'any';
          state.myList[new_id].srcCidr = "";
          state.myList[new_id].destCidr = "";
          state.myList[new_id].syslogEnabled = false;
          if (state.displayGrp.id){
              state.myList[new_id].knum_groupe = state.displayGrp.id;
          } else {
             state.myList[new_id].knum_groupe = null;
          }
          console.log(emptyObject,element)
        } 
        if (action === "add_before") {
          const new_id = list_id
          state.myList.splice(new_id, 0,emptyObject)
          state.myList[new_id].policy = 'allow';
          state.myList[new_id].protocol = 'any';
          state.myList[new_id].srcCidr = "";
          state.myList[new_id].destCidr = "";
          state.myList[new_id].syslogEnabled = false;
          if (state.displayGrp.id){
              state.myList[new_id].knum_groupe = state.displayGrp.id;
          } else {
             state.myList[new_id].knum_groupe = null;
          }  
        } 
        if (action === "duplicate"){
          const duplicateObject = keys.reduce((acc, key) => {
          acc[key] = element[key];
          return acc;
        }, {});
          state.myList.splice(list_id +1, 0,duplicateObject)
        } 
        recalcLngs();
      } 
      

      // ADD OBJECTS
      const tableHeaderObj = ref([
        { name: "Nom", key: "name", sortable: false},
        { name: "CIDR", key: "cidr", sortable: false,},
        { name: "Category", key: "category", sortable: false,},
      ]);

      const selectLineObj = (data) => {
        data.select = !data.select;
        state.gObjects.filter((item: any) =>item.id == data.id)[0].select = !state.gObjects.filter((item: any) =>item.id == data.id)[0].select;
      };

      const promptObj = (source,idLng) => {
        state.gObjects.forEach(element => {
          element.select = false;
        });
        state.currentSource = source;
        state.currentIDLng = idLng;
        state.drawerObj = true;
      }

      const ajoutObj = () => {
        state.gObjects.forEach(function(item) {
          if (item.select) {
            const value = "OBJ("+ item.id +")";
            if (state.currentSource == 'source') state.myList[state.currentIDLng].srcCidr += (state.myList[state.currentIDLng].srcCidr ? "," : "") + value;
            console.log( state.myList[state.currentIDLng].srcCidr )
            if (state.currentSource == 'destination') state.myList[state.currentIDLng].destCidr += (state.myList[state.currentIDLng].destCidr ? "," : "") + value;
          }
        });

        state.loadingDatatable += 1;
        state.displayBtns = true;
        state.drawerObj = false;
      }


      // ADD GROUP OBJECTS
      const tableHeaderObjGroup = ref([
        { name: "Nom", key: "name", sortable: false},
        { name: "Objets", key: "objectIds", sortable: false,},
      ]);

      const selectLineObjGroup = (data) => {
        data.select = !data.select;
        state.gObjectGroup.filter((item: any) =>item.id == data.id)[0].select = !state.gObjectGroup.filter((item: any) =>item.id == data.id)[0].select;
      };

      const promptObjGroup = (source,idLng) => {
        state.gObjectGroup.forEach(element => {
          element.select = false;
        });
        state.currentSource = source;
        state.currentIDLng = idLng;
        state.drawerObjGroup = true;
      }


      const ajoutObjGroup = () => {
        state.gObjectGroup.forEach(function(item) {
          if (item.select) {
            const value = "GRP("+ item.id +")";
            if (state.currentSource == 'source') state.myList[state.currentIDLng].srcCidr += (state.myList[state.currentIDLng].srcCidr ? "," : "") + value;
            console.log( state.myList[state.currentIDLng].srcCidr )
            if (state.currentSource == 'destination') state.myList[state.currentIDLng].destCidr += (state.myList[state.currentIDLng].destCidr ? "," : "") + value;
          }
        });

        state.loadingDatatable += 1;
        state.displayBtns = true;
        state.drawerObjGroup = false;
      }

      const promptIPV4 = (source,idLng) => {
        ElMessageBox.prompt('Veuillez saisir un CIDR IPv4 valide', 'Nouvelle '+source, {
          confirmButtonText: 'OK',
          cancelButtonText: 'annuler',
          inputPattern: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/(3[0-2]|[12]?[0-9]))?$/,
          inputErrorMessage: 'CIDR IPv4 invalide',
        })
        .then(({ value }) => {
            state.displayBtns = true;
            if (source == 'source') state.myList[idLng].srcCidr += (state.myList[idLng].srcCidr ? "," : "") + value;
            if (source == 'destination') state.myList[idLng].destCidr += (state.myList[idLng].destCidr ? "," : "") + value;
            state.loadingDatatable += 1;
          })
      }

      const promptIPV6 = (source,idLng) => {
        ElMessageBox.prompt('Veuillez saisir un CIDR IPv6 valide', 'Nouvelle '+source, {
          confirmButtonText: 'OK',
          cancelButtonText: 'annuler',
          inputPattern: /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))(\/(12[0-8]|1[01][0-9]|[1-9]?[0-9]))?$/,
          inputErrorMessage: 'CIDR IPv6 invalide',
        })
          .then(({ value }) => {
            state.displayBtns = true;
            if (source == 'source') state.myList[idLng].srcCidr += (state.myList[idLng].srcCidr ? "," : "") + value;
            if (source == 'destination') state.myList[idLng].destCidr += (state.myList[idLng].destCidr ? "," : "") + value;
            state.loadingDatatable += 1;
          })
      }

      const promptFree = (source,idLng) => {
        ElMessageBox.prompt('Veuillez saisir un domaine valide', 'Nouvelle '+source, {
          confirmButtonText: 'OK',
          cancelButtonText: 'annuler'
        })
          .then(({ value }) => {
            state.displayBtns = true;
            if (source == 'source') state.myList[idLng].srcCidr += (state.myList[idLng].srcCidr ? "," : "") + value;
            if (source == 'destination') state.myList[idLng].destCidr += (state.myList[idLng].destCidr ? "," : "") + value;
            state.loadingDatatable += 1;
          })
      }


      // VALID CHANGES FIREWALL
      const validFirewall = async () => {
        state.loaderEnabled = true;
        state.errorsTests = "";

        const form = JSON.stringify(state.initialMyList);
        const response = await mAxiosApi.post("/update_firewall?source=1&network_id=" + props.idNetwork , form, { headers: {   'Content-Type': 'application/json'  }  });
          
        state.loaderEnabled = false;

        if (response.data.errors) {         
          state.errorsTests = response.data.errors;

        }else {
          applyFirewallToLinks();
          addCategoryToSelectFilter()
        }


      };


      const addCategoryToSelectFilter = (rules = state.initialMyList) => {
        const uniqueCategories = new Set(rules.map((d)=>d.category).filter((d)=>d != "")) 
        let arrOfOptions = [] as any
        uniqueCategories.forEach((data)=> ( arrOfOptions.push({label:data,value:data})))
        state.categoriesOptions = arrOfOptions
      }


      const applyFirewallToLinks = async () => {

        state.loaderEnabled = false;
        state.errorsTests = "";

        state.applyLinks = true;
        state.linksStep = 0;
        
        const form = JSON.stringify(state.initialMyList);

        const maxConcurrentRequests = 9;
        const links = state.mNetworksLink.slice(); 
        const totalLinks = links.length; 
        const results: any[] = [];

        let processedLinks = 0;

        while (links.length > 0) {
          const batch = links.splice(0, maxConcurrentRequests);
          const batchPromises = batch.map(async (link) => {
            try {
              const response = await mAxiosApi.post("/update_firewall?network_id=" + link.net_id , form, { headers: {   'Content-Type': 'application/json'  }  });

              processedLinks++;
              state.linksStep = Math.round((processedLinks / totalLinks) * 100);

              return response.data;
            } catch (error) {
              console.error(`Une erreur s'est produite lors de la requête Axios pour ${link}:`, error);
              return null;
            }
          });

          const batchResults: any = await Promise.all(batchPromises);
          results.push(...batchResults);

          await new Promise(resolve => setTimeout(resolve, 1000));
        }

        ElNotification({ title: 'Succés', message: 'Règles appliquées avec succées sur tous les réseaux liés', type: 'success',})

        state.displayBtns=false;
        state.applyLinks = false;
        state.displayBtns = false;


      };
   

      const updateListWithCategory = (event) =>{
        if(event.length > 0){
          const filerL = state.initialMyList.filter((item)=> {
            if(item.category == null){
              return false
            } 
            return event.includes(item.category.trim())
          })
          state.myList = filerL 
        }else{
          state.myList = state.initialMyList 
        }
      }


     return {
        addCategoryToSelectFilter,
        state,
        store,
        tableHeader,
        tableFilters,
        fieldCIDR,
        fieldCIDREdit,
        delElement,
        addEmptyRowAtStart,
        delLignes,
        buildList,
        promptIPV4,
        promptIPV6,
        promptFree,
        validFirewall,
        dropRule,
        promptVLAN,
        tableHeaderVLAN,
        selectLineVLAN,
        ajoutVLAN,
        tableHeaderObj,
        selectLineObj,
        promptObj,
        ajoutObj,
        tableHeaderObjGroup,
        selectLineObjGroup,
        promptObjGroup,
        ajoutObjGroup,
        addEditGrp,
        openAddEditGrp,
        displayGrpLinkNetwork,
        tableHeaderGroupLink,
        selectLineGroupLink,
        editGroupeLinkedNetwork,
        testFilterGRP,
        returnOpacityFilterGRP,
        addLine,
        updateListWithCategory,
      };
    },
  });
  
  export async function getAxios(request: string): Promise<any> {
    const response = await mAxiosApi.get(request);
    return response.data;
  }
  